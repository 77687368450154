import {Component} from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html'
import 'draft-js/dist/Draft.css'

interface Props {
  field
  nameField
  idioma
  onChange
}

interface State {
  editorState
  contentState
}

export class EditorOwn extends Component<Props, State> {
  constructor(props) {
    super(props);

    const contenido = this.props.field ? (this.props.field[this.props.idioma] ?? '') : ''
    const contentBlock = htmlToDraft(contenido);

    let contentState;
    if (contentBlock) {
      contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    }
    this.state = {
      editorState: EditorState.createWithContent(contentState),
      contentState,
    };
  }

  onEditorStateChange = (editorState) => {
    this.props.onChange(this.props.nameField, draftToHtml(convertToRaw(editorState.getCurrentContent()), this.props.idioma))
    this.setState({
      editorState,
    });
  };

  render() {
    const {editorState, contentState} = this.state;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.onEditorStateChange}
        defaultEditorState={contentState}
      />
    )
  }
}

