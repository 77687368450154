import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {PasarelaPago} from '../@types/Data';
import {
  getClientByIDPC,
  updateClientToken,
  getDataLogin,
  subirImagen,
  getLogo,
  getPasarelas,
  getPasarelaOptions
} from './requests/RequestMaker';
import {useTranslation} from 'react-i18next';
import {MasterLayout} from '../_metronic/layout/MasterLayout';
import {useGlobalContext} from '../utils/MyGlobalContext';
import {FormItem} from './Widgets/FormItem';
import Swal from 'sweetalert2';
import {Col, FormGroup, Label, Spinner} from 'reactstrap';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import MaterialTable from 'material-table';
import {tableIcons} from './Widgets/tableIcons';
import {addUrl, patchUrl, updateUrl} from './requests/urls_clientes';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';


interface DataClientParams {
  id: string;
}

type DataClientProps = RouteComponentProps<DataClientParams>;

type tableData = {
  id?: number;
};

export const DataClient: React.FC<DataClientProps> = (props: DataClientProps) => {
  const [t, i18n] = useTranslation("global");

  const SPAIN: PasarelaPago = {
    id: 1, name: 'es'
  }

  const ENGLISH: PasarelaPago = {
    id: 2, name: 'en'
  }

  const items = [
    {name: 'Dominio', required: true, placeholder: `${t("clientsData.domain")}`, type: 'text', readOnly: false}
  ]

  const initials = {
    id: "",
    idTicando: "",
    Cliente: "",
    PUBLICAPIKEY: "",
    PRIVATEAPIKEY: "",
    Dominio: "",
    email: "",
    idiomaNotificacion: ""
  }

  const [cliente, setCliente] = useState<any>(initials);
  const [logoURL, setLogoURL] = useState<any>(undefined)
  const [pasarelas, setPasarelas] = useState<any>()
  const [pasarela_options, setPasarela_options] = useState<any>()
  const [url, setUrl] = useState<any>("")
  const [urls, setUrls] = useState<any>();
  const [mensajeInfoGreenpay, setMensajeInfoGreenpay] = useState<boolean>(false)
  const [openListUrls, setOpenListUrls] = React.useState(false);

  const [columns,] = useState([
    {title: 'Dominio', field: 'url'},
  ]);

  const history = useHistory();
  const id = props.match.params.id;
  const {copy} = useGlobalContext();

  let default_claves = '*******************'

  let idioma: any = null;
  idioma = sessionStorage.getItem('idioma');
  if (idioma == null) {
    idioma = 'ESPAÑOL';
  }

  let token = sessionStorage.getItem('token');

  if (!token) {
    token = '';
  }

  useEffect(() => {
    if (!pasarelas) {
      getPasarelas().then(res => setPasarelas(res));
    }
  }, [])

  useEffect(() => {

    i18n.changeLanguage("es");
    if (idioma.localeCompare("INGLÉS") === 0) {
      i18n.changeLanguage("en");
    } else if (idioma.localeCompare("FRANCÉS") === 0) {
      i18n.changeLanguage("fr");
    } else if (idioma.localeCompare("ESPAÑOL") === 0) {
      i18n.changeLanguage("es");
    }

    const storage = sessionStorage.getItem('sessionLogin');
    if (!storage) {
      history.push('/login');
    } else {
      if (!JSON.parse(storage)) {
        history.push('/login');
      } else {
        const token = sessionStorage.getItem('token');
        if (token) {
          getClientByIDPC(token).then((res) => {
            if (res.code === 456) {
              Swal.fire({
                icon: 'error',
                title: `${t("main.invalidToken")}`,
                text: `${t("main.invalidTokenmsg")}`,
              })
              history.push('/login');
            } else {
              res[0].pasarela_options = res[0].pasarela_options.filter(op => op && op.keyPasarela &&
                (op.keyPasarela !== 'CURRENCY_CREDOMATIC' || (op.keyPasarela === 'CURRENCY_CREDOMATIC' && op.value) || (op.keyPasarela === 'CURRENCY_CREDOMATIC' && op.value === null))
              )
              setCliente(res[0]);
              if (res[0]) {
                setUrls(res[0].urls)
              }
              if (res[0].pasarela_pago && res[0].pasarela_pago.name === 'GREENPAY') {
                setMensajeInfoGreenpay(true)
              }

              getLogo(res[0].id).then(r => {
                setLogoURL(r.url)
              });

              // Filtrar los campos de la pasarela de pago activa
              let aux = [];
              res = res[0]
              res.pasarela_options.forEach(op => {
                if (op.pasarela_id === res.pasarela_id) {
                  aux.push(op)
                }
              });
              if (aux.length !== 0) { // En la tabla pasarelaOptions hay definidas filas para la pasarela activa
                setPasarela_options(aux)
              }
            }
          });

          getDataLogin(token).then((res) => {
            if (res.code === 456) {
              Swal.fire({
                icon: 'error',
                title: `${t("main.invalidToken")}`,
                text: `${t("main.invalidTokenmsg")}`,
              })
              setTimeout(() => history.push('/login'), 4500);
            } else {
              if (res[0].role.localeCompare('admin') !== 0) {
                history.push('/');
              }
            }
          });
        }
      }
    }
  }, [id, history]);

  const handleChangeUrl = (event) => {
    setUrl(event.target.value)
  }

  const handleClickOpen = () => {
    setOpenListUrls(true);
  };

  const handleClose = () => {
    setOpenListUrls(false);
  };

  const handleAddUrl = async (url) => {
    if (url !== "") {
      setUrls([...urls, url])
      await addUrl(url, token)
    }
    setUrl("")
  };

  const cambiarActivacion = async (data) => {
    if (token) {
      patchUrl(data.id, data.disabled_at ? false : true, token).then(res => { // disabled = true, deshabilitamos la url
        const dataDelete = [...urls];
        const tableData: tableData = data.tableData
        const index = tableData.id;
        dataDelete[index].disabled_at = !data.disabled_at
        // dataDelete.splice(index, 1);
        setUrls([...dataDelete]);
      })
    }
  }

  const changeOptionsHandler = (keyPasarela, v) => {
    const newOptions = pasarela_options.map(op => {
      if (op.keyPasarela === keyPasarela) {
        return {
          ...op,
          value: v
        }
      }
      return op;
    })
    setPasarela_options(newOptions)
  }

  const handleChangeLogo = async (event) => {
    const file = event.target.files[0] //aquí recibirías el archivo                                       
    const data = new FormData()
    data.append('imagen', file);
    data.append('idCliente', cliente.id);
    data.append('nameImage', `logoCliente`)
    data.append('folder', '')
    const res = await subirImagen(data);
    setLogoURL(res.secure_url)
  }

  const changeHandler = (name: any, value: any) => {
    if (name === 'pasarelaPago') {
      if (value === 'GREENPAY') {
        setMensajeInfoGreenpay(true)
      } else {
        setMensajeInfoGreenpay(false)
      }

      // Hay que hacer una peticion para ver si la nueva pasarela seleccionada tiene ya filas en la tabla pasarelasOptions
      getPasarelaOptions(value, cliente.id, token).then(res => {
        if (res.length === 0) { // No existen esos campos en la tabla. Obtenerlos por tabla o codigo duro
          let pasarela = pasarelas.filter(p => p.name === value)
          pasarela = pasarela[0]
          let aux = [];
          if (pasarela) {
            pasarela.fields.forEach(field => {
              let fila = {
                client_id: cliente.id,
                pasarela_id: pasarela.id,
                keyPasarela: field.nameField,
                value: ''
              }
              aux.push(fila)
            })
            setPasarela_options(aux)
          }
        } else {
          setPasarela_options(res)
        }
      })
    }
    setCliente({...cliente, [name]: value})
  }

  const updateClientHandler = () => {
    if (cliente) {
      if (token) {
        updateClientToken(token,
          cliente.idTicando,
          cliente.Dominio,
          cliente.idiomaNotificacion,
          pasarela_options
        ).then((res) => {
          if (res === 1) {
            Swal.fire({
              icon: 'success',
              title: `${t("clientsData.success")}`,
              text: `${t("clientsData.msgSuccess")}`,
            })
          } else if (res.code === 456) {
            Swal.fire({
              icon: 'error',
              title: `${t("main.invalidToken")}`,
              text: `${t("main.invalidTokenmsg")}`,
            })
            setTimeout(() => history.push('/login'), 4500);
          } else if (res.code === 41) {
            Swal.fire({
              icon: 'error',
              title: `Error`,
              text: res.error,
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: `${t("clientsData.error")}`,
              text: `${t("clientsData.msgError")}`,
            })
          }
        });
      }
    }
  }

  let disabledActualizar = false;
  if (pasarela_options) {
    pasarela_options.forEach(op => {
      if (op.value === '' && op.keyPasarela !== 'CHANNEL_ID_TMT') {
        disabledActualizar = true;
        // setDisabledActualizar(true)
      }
    })
  }

  return (
    <MasterLayout>
      {
        cliente.Cliente && copy === 'admin' &&
        <div className='col-xl-12'>
          <div className='card mb-2 mb-xl-5'>
            <div
              className='card-header border-0 cursor-pointer'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'>
              <div className='card-title m-0' style={{alignContent: 'center'}}>
                <h1 className='fw-bolder m-0'>{t("main.customerData")}</h1>
              </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-9'>

                <div className='row mb-6'>
                  <h2 style={{color: '#0a3154'}}>Logo</h2>
                  <div className='col-lg-8' style={{marginBottom: '35px', marginTop: '10px', marginLeft: '10px'}}>
                    {
                      logoURL &&
                      <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img src={logoURL} alt="Metornic" className="img-fluid __web-inspector-hide-shortcut__"/>
                      </div>
                    }
                    <br/>
                    <input style={{marginTop: '10px'}} type="file" id="fileupload" accept="image/*"
                           onChange={async (event) => handleChangeLogo(event)}/>
                  </div>
                </div>

                <FormItem
                  name={null}
                  value={cliente.Cliente}
                  placeholder={t("clientsData.business")}
                  type={'text'}
                  readOnly
                  onChange={null}
                />

                <FormGroup row>
                  <Label
                    style={{
                      fontWeight: 500,
                      fontFamily: 'Barlow Condensed',
                      fontSize: '1.4rem',
                    }}
                    for={'pasarelaPago'}
                    md={5}
                    sm={12}
                  >
                    {t("clientsData.paymentsGateway")}
                  </Label>
                  <Col
                    md={7}
                    sm={12}
                  >
                    {
                      cliente.pasarela_pago && cliente.pasarela_pago.name === 'TRUSTMYTRAVEL' &&
                      <input
                        type='text'
                        readOnly
                        className='form-control form-control-lg form-control-solid'
                        style={{backgroundColor: '#fffff'}} placeholder='Pasarela'
                        value={cliente.pasarela_pago && cliente.pasarela_pago.name ? cliente.pasarela_pago.name : 'No tiene definida pasarela de pago'}/>

                    }

                    {
                      cliente.pasarela_pago && cliente.pasarela_pago.name !== 'TRUSTMYTRAVEL' &&
                      <select
                        className='form-select form-select-solid form-select-lg'
                        name="pasarelaPago"
                        onChange={(e) => changeHandler(e.target.name, e.target.value)}>
                        {
                          !cliente.pasarela_pago &&
                          <option value={'select'} key={0}>Seleccione una pasarela de pago...</option>
                        }
                        {
                          pasarelas && pasarelas.map(pasarela => {
                            return (
                              <option
                                value={pasarela.name}
                                key={pasarela.id}
                                selected={cliente.pasarela_pago && cliente.pasarela_pago.name === pasarela.name}>{pasarela.name}</option>
                            )
                          })
                        }
                      </select>
                    }
                  </Col>
                </FormGroup>

                {
                  cliente.pasarela_pago && cliente.pasarela_pago.name === 'MULTISAFEPAY' && pasarela_options && pasarela_options.map((option, index) => {
                    console.log('pasarela:options: ', pasarela_options)
                    return (
                      <FormItem
                        name={option.keyPasarela}
                        value={option.value}
                        placeholder={option.keyPasarela}
                        required={false}
                        onChange={(e) => changeOptionsHandler(option.keyPasarela, e.target.value)}
                        type={'text'}
                        readOnly={false}
                        t={t}
                        isPasarelaOption={option.keyPasarela !== 'CHANNEL_ID_TMT' && option.keyPasarela !== 'CURRENCY_CREDOMATIC'}
                        // showEye={option.value}
                      />
                    )
                  })
                }
                {
                  cliente.pasarela_pago && cliente.pasarela_pago.name === 'TRUSTMYTRAVEL' && pasarela_options && pasarela_options.map((option, index) => {
                    console.log('pasarela:options: ', pasarela_options)
                    return (
                      <FormItem
                        name={option.keyPasarela}
                        value={(option.keyPasarela === 'CHANNEL_ID_TMT' || option.keyPasarela === 'CURRENCY_CREDOMATIC') ? option.value : null}
                        placeholder={option.keyPasarela}
                        required={false}
                        onChange={(e) => changeOptionsHandler(option.keyPasarela, e.target.value)}
                        type={(option.keyPasarela !== 'CHANNEL_ID_TMT' && option.keyPasarela !== 'CURRENCY_CREDOMATIC') ? 'password' : 'text'}
                        readOnly={true}
                        t={t}
                        isPasarelaOption={option.keyPasarela !== 'CHANNEL_ID_TMT' && option.keyPasarela !== 'CURRENCY_CREDOMATIC'}
                        showEye={option.value}
                      />
                    )
                  })
                }

                {
                  cliente.additionalCommission &&
                  <FormItem
                    name={'additionalCommission'}
                    value={cliente.additionalCommission + '%'}
                    placeholder={''}
                    type={'text'}
                    readOnly
                    onChange={null}
                  />
                }

                <FormGroup row>
                  <Label
                    style={{
                      fontWeight: 500,
                      fontFamily: 'Barlow Condensed',
                      fontSize: '1.4rem',
                    }}
                    for={'pasarelaPago'}
                    md={5}
                    sm={12}
                  >
                    {t("clientsData.paymentsGateway")}
                  </Label>
                  <Col
                    md={7}
                    sm={12}
                  >
                    <select
                      className='form-select form-select-solid form-select-lg'
                      name="idiomaNotificacion"
                      defaultValue={cliente.idiomaNotificacion}
                      onChange={(e) => changeHandler(e.target.name, e.target.value)}>
                      <option
                        value={'null'}
                        key={0}
                      >
                        {t("clientsData.selectNofitificationsLanguage")}
                      </option>
                      <option
                        value={SPAIN.name}
                        key={SPAIN.id}
                        selected={cliente.idiomaNotificacion === SPAIN.name}>{t("languages.spanish")}</option>
                      <option value={ENGLISH.name} key={ENGLISH.id}
                              selected={cliente.idiomaNotificacion === ENGLISH.name}>{t("languages.english")}</option>
                    </select>
                  </Col>
                </FormGroup>
                
                {
                  items && items.map((item) => {
                    return (
                      <FormItem
                        name={item.name}
                        value={cliente[item.name]}
                        placeholder={item.placeholder}
                        required={item.required}
                        onChange={(e) => changeHandler(e.target.name, e.target.value)}
                        type={item.type}
                        readOnly={item.readOnly}
                        t={t}
                      />
                    )
                  })
                }
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' disabled={disabledActualizar} className='btn btn-primary'
                        onClick={updateClientHandler}>
                  {t("clientsData.update")}
                </button>
              </div>
              <div className='col-10 fv-row' style={{margin: '0 auto'}}>
                <MaterialTable
                  title={t("clientsData.othersDomains")}
                  columns={columns}
                  data={urls}
                  icons={tableIcons}
                  style={{boxShadow: 'none', border: '1px solid beige'}}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(async () => {
                          await addUrl(newData.url, token)
                          setUrls([...urls, newData]);
                          resolve(null)
                        }, 1000)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(async () => {
                          const dataUpdate = [...urls];
                          const tableData: tableData = oldData.tableData
                          const index = tableData.id;
                          dataUpdate[index] = newData;
                          await updateUrl(oldData.id, newData.url, token)
                          setUrls([...dataUpdate]);
                          resolve(null)
                        }, 1000)
                      })
                  }}
                  actions={[
                    rowData => (
                      {
                        icon: rowData.disabled_at ? ToggleOffOutlinedIcon : ToggleOnIcon,
                        tooltip: rowData.disabled_at ? `${t("clientsData.enableDomain")}` : `${t("clientsData.disableDomain")}`,
                        onClick: (e, data: any) => {
                          cambiarActivacion(data)
                        }
                      }
                    )
                  ]}
                  options={{
                    pageSizeOptions: [5, 10, 25, 50],
                    paginationType: "stepped",
                    actionsColumnIndex: -1,
                    toolbarButtonAlignment: "left",
                    rowStyle: (data, index) => data.disabled_at ? {color: "grey"} : null
                  }}
                />
              </div>

              <Dialog open={openListUrls} onClose={handleClose}>
                <DialogTitle>{t("clientsData.othersDomains")}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <ul>
                      {urls &&
                      urls.map(url => {
                        return (
                          <li>
                            <a href={url}>{url}</a>
                          </li>
                        )
                      })
                      }
                    </ul>
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="url"
                    label="Url del sitio web"
                    type="url"
                    fullWidth
                    variant="standard"
                    onChange={handleChangeUrl}
                    value={url}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>{t("clientsData.exit")}</Button>
                  <Button onClick={handleAddUrl}>{t("clientsData.add")}</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      }
      {
        !cliente.Cliente &&
        <div style={{top: '50%', left: '50%,', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
        </div>
      }
    </MasterLayout>
  );
}