import React, {ChangeEvent, useState} from "react";
import {Button, Col, FormGroup, Input, InputGroup, Label} from 'reactstrap'
import {InputType} from 'reactstrap/es/Input'

interface ItemProps {
  name: string
  value: string
  label?: string
  placeholder: string
  required?: boolean
  type
  readOnly?: boolean
  t?: any
  isPasarelaOption?: boolean
  showEye?: boolean

  onChange(event: ChangeEvent<HTMLInputElement>)
}

export const FormItem = ({name, value, label, placeholder, required, onChange, type, readOnly, t, isPasarelaOption, showEye}: ItemProps) => {

  const [show, setShow] = useState<boolean>(false)
  const [inputType, setInputType] = useState<InputType>(type)

  const changeShow = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
    setShow(!show)
  }

  return (
    <FormGroup row>
      <Label
        style={{
          fontWeight: 500,
          fontFamily: 'Barlow Condensed',
          fontSize: '1.4rem',
        }}
        for={name}
        md={5}
        sm={12}
      >
        {label || placeholder}
      </Label>
      <Col
        md={7}
        sm={12}
      >
        <InputGroup >
          <Input
            className="form-control border-1 border-secondary"
            placeholder={isPasarelaOption ? '************' : placeholder}
            readOnly={readOnly}
            name={name}
            value={value}
            type={inputType}
            onChange={onChange}
            style={{backgroundColor: '#fffff', padding: "20px 20px 25px"}}
          />
          {showEye &&
          <Button
            className={`${!show ? 'bi bi-eye-slash' : 'bi-eye'}  border-1 border-secondary `}
            style={{/*backgroundColor: 'black', paddingLeft: '10px', paddingRight: '10px'*/}}
            onClick={changeShow}
          />}
        </InputGroup>

      </Col>

      <Col>
        {
          name === 'TERMINAL_GREENPAY' && t &&
          <div className='col-lg-8 fv-row'>
            <span style={{paddingLeft: '15px', color: 'mediumblue'}}>{t("clientsData.terminalGreenpay")}</span>
          </div>
        }
        {
          name === "UUID_SERVICE_PAYNOPAIN" && t &&
          <div className='col-lg-12 fv-row'>
                  <span
                    style={{paddingLeft: '15px', color: 'mediumblue'}}>{t("clientsData.uuid_service_paynopain")}</span>
          </div>
        }
      </Col>
    </FormGroup>
  )
}
