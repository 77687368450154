import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {getDataLogin} from './requests/RequestMaker'
import {useTranslation} from 'react-i18next';
import {PasarelaPago} from '../@types/Data';
import {MasterLayout} from '../_metronic/layout/MasterLayout';
import {useGlobalContext} from '../utils/MyGlobalContext';
import Swal from 'sweetalert2'
import {FormItem} from './Widgets/FormItem';
import {getUsers, updateUser} from './requests/RequestMaker';
import {Checkbox, FormControlLabel, FormGroup} from '@material-ui/core';


interface UpdateUserParams {
  username: string;
}

type UpdateUserProps = RouteComponentProps<UpdateUserParams>;

export const UpdateUser: React.FC<UpdateUserProps> = (props: UpdateUserProps) => {

  const [t, i18n] = useTranslation("global");

  const items = [
    {name: 'username', required: true, placeholder: `${t("users.username")}`, type: 'text', readOnly: false},
    {name: 'password', required: true, placeholder: `${t("users.password")}`, type: 'password', readOnly: false},
    {name: 'email', required: true, placeholder: `${t("users.email")}`, type: 'text', readOnly: false},
  ]

  const admin: PasarelaPago = {
    id: 1, name: 'admin'
  }

  const editor: PasarelaPago = {
    id: 2, name: 'editor'
  }

  const initials = {
    username: "",
    name: "",
    surname: "",
    email: "",
    rol: "",
    password: "",
    sendEmailNotifications: false
  }

  const history = useHistory();
  const id = +props.match.params.username;
  const [user, setUser] = useState<any>();

  const {copy,} = useGlobalContext();

  let idioma: any = null;
  idioma = sessionStorage.getItem('idioma');
  if (idioma == null) {
    idioma = 'ESPAÑOL';
  }
  let token = sessionStorage.getItem('token');
  if (!token) {
    token = '';
  }

  useEffect(() => {

    i18n.changeLanguage("es");
    if (idioma.localeCompare("INGLÉS") === 0) {
      i18n.changeLanguage("en");
    } else if (idioma.localeCompare("FRANCÉS") === 0) {
      i18n.changeLanguage("fr");
    } else if (idioma.localeCompare("ESPAÑOL") === 0) {
      i18n.changeLanguage("es");
    }
    const storage = sessionStorage.getItem('sessionLogin');
    if (!storage) {
      history.push('/login');
    } else {
      if (!JSON.parse(storage)) {
        history.push('/login');
      } else {
        const token = sessionStorage.getItem('token');
        if (token) {
          if (!user) {
            getUsers(token).then((res) => {
              if (res.code === 456) {
                Swal.fire({
                  icon: 'error',
                  title: `${t("main.invalidToken")}`,
                  text: `${t("main.invalidTokenmsg")}`,
                })
                history.push('/login')
                return null;

              }
              res.forEach((r) => {
                if (r.id === id) {
                  setUser(r);

                  if (!copy) {
                    getDataLogin(token).then((res) => {
                      //changeHandler("role",res[0].role);
                      // setRole(res[0].role);     
                      if (res[0].role !== 'admin') {
                        history.push('/');
                      }
                    });

                  } else {
                    if (copy !== 'admin') {
                      history.push('/');
                    }
                  }
                }
              })
            });
          }
        }
      }
    }
  }, [user]);

  const changeHandler = (name: any, value: any) => {
    setUser({...user, [name]: value})
  }

  const updateUserHandler = () => {

    if (user) {
      if (token) {
        let sendEmailNotifications = user.role === admin.name ? user.sendEmailNotifications : false
        updateUser(token,
          user.id,
          user.username,
          user.name,
          user.surname,
          user.password,
          user.email,
          user.role,
          sendEmailNotifications
        ).then((res) => {
          if (res === 1) {
            history.push('/users');
          } else if (res.code === 456) {
            Swal.fire({
              icon: 'error',
              title: `${t("main.invalidToken")}`,
              text: `${t("main.invalidTokenmsg")}`,
            })
            history.push('/login')
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: t('users.missingData'),
              footer: ''
            })
          }
          /*if(res.message === 'Invalid request payload input'){
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: t('users.missingData'),
                  footer: ''
                })                           
              }else{
                  history.push('/users');
              }*/

        });
      }
    }
  }

  return (

    <MasterLayout>
      {
        user && copy === 'admin' &&
        <div className='col-xl-12'>
          <div className='card mb-2 mb-xl-5'>
            <div
              className='card-header border-0 cursor-pointer'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0' style={{alignContent: 'center'}}>
                <h3 className='fw-bolder m-0'><h1>{t("users.user")} {user.name}</h1></h3>
              </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-9'>
                <FormItem 
                  name={'name'} 
                  value={user.name} 
                  placeholder={t("users.name")} 
                  type={'text'} 
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                />
                <FormItem 
                  name={'surname'} 
                  value={user.surname} 
                  placeholder={t("users.surname")} 
                  type={'text'} 
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                />
                {
                  items && items.map((item) => {
                    return (
                      <FormItem
                        name={item.name}
                        value={user[item.name]}
                        placeholder={item.placeholder}
                        required={item.required}
                        onChange={(e) => changeHandler(e.target.name, e.target.value)} type={item.type}
                        readOnly={item.readOnly}
                      />
                    )
                  })

                }
                <div className='row mb-6'>
                  <div className="col-lg-4">
                    <h2 style={{color: '#0a3154'}}>{t("users.role")}</h2>
                  </div>
                  <div className='col-lg-8 fv-row'>
                    <select className='form-select form-select-solid form-select-lg' name="role"
                            onChange={(e) => changeHandler(e.target.name, e.target.value)}>
                      <option value={admin.name} key={admin.id} selected={user.role === admin.name}>Admin</option>
                      <option value={editor.name} key={editor.id} selected={user.role === editor.name}>Editor</option>
                    </select>
                  </div>
                  <br></br>
                  <div className='row mb-6'>
                    {
                      user.role === admin.name &&
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={user.sendEmailNotifications || user.name === 'Administrador'}
                                             color='primary' onChange={(e) => {
                            if (user.name === 'Administrador') {
                              changeHandler('sendEmailNotifications', true)
                            } else {
                              changeHandler('sendEmailNotifications', !user.sendEmailNotifications)
                            }
                          }} required={false} inputProps={{'aria-label': 'controlled'}}/>}
                          label={`${t("users.sendNotificationsmsg")}`}/>
                      </FormGroup>
                    }
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button onClick={(e) => {
                  history.push('/users')
                }} className="btn btn-secondary">{t("updateExperience.back")}</button>
                <button type='submit' className='btn btn-primary'
                        onClick={updateUserHandler}>{t("clientsData.update")}</button>
              </div>

            </div>

          </div>
        </div>

      }
    </MasterLayout>
  );
}


